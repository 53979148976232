
import axios from 'axios'
import api from '../config/api'

var urlTgmLogin = api.protocolApi +  "://" + api.urlApi + "/api/login_check";
var urlTgmLoginUsers = api.protocolApi + "://" + api.urlApi + "/api/users";
var mocks = {
  "login_check" : { POST: { token: "This-api-mocked-token" } },
  auth: { POST: { token: "This-is-a-mocked-token" } },
  "users": { GET: { name: "doggo", title: "sir" } },
  "user/me": { GET: { name: "doggo", title: "sir" } }
};

const header = { headers: { "Content-Type":"application/json" } }

const apiCall = ({ url, data, method }) =>
  new Promise((resolve, reject) => {
    //console.log("user , password => ", data.username, data.password)
    if(method=="POST"){
    var dataValue = { "email": data.username, "password": data.password};
    var urlTgm = api.protocolApi + "://" + api.urlApi + "/api/login_check";
    axios.post(urlTgm,dataValue,header)
      .then(response => {
        //console.log("response => " + JSON.stringify( response) )
        mocks["login_check"]["POST"] = { "token" :  response.data.token };
        console.log("[api.js] new ? mocks[url][POST][token] => ",  mocks["login_check"]["POST"]["token"])
        localStorage.setItem('accessToken', response.data.token);
        localStorage.setItem('accessEmail', data.username);
        
        //var appelUser = JSON.parse(response.data.data)$=
        console.log("[api.js] accessToken ok response=>" +  JSON.stringify(response) );
        location.href = '/'
        //router.push({ name: "dashboard"});
        //console.log("[api.js] go");
      })
      .catch(error => {
        var retour="error";
        console.log("[api.js] catch " + urlTgm)
      })
    }
    else if(method=="GET")
    {
      /*
      var urlTgm = api.protocolApi + "://" +  api.urlApi + "/api/users";
      console.log("urlTgm => ", urlTgm)
      axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('accessToken');
      axios.get(urlTgm)
        .then(response => {
          console.log("response.data.token => ", response.data.token)
          mocks["users"]["GET"] = { "data" :  response };
          console.log("[api.js] new ? mocks[url][GET][name] => ",  mocks["users"]["GET"]["name"]);

        })
        .catch(error => {
          var retour="error";
        })
        */
    }
    
    setTimeout(() => {
      try {
        console.log("[api.js] setTimeout url => ", url )
        //this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push({ name: 'Login'}));
        //location.href = '/'
        /*console.log("[api.js] setTimeout method => ", method )
        if(method=="POST"){
        console.log("[api.js] setTimeout mocks[url][method || GET].token => ", mocks["login_check"][method || "GET"].token)
        }
        else{
          console.log("[api.js] setTimeout mocks[url][method || GET].name => ", mocks["user/me"][method || "GET"].name)
        }

        resolve(mocks["login_check"][method || "GET"]);
        //console.log("[api.js]" );
        console.log("[api.js] response setTimeout: ", mocks["login_check"][method || "GET"]);
        
        //localStorage.setItem('data.username', data.username);
        //localStorage.setItem('data.password', data.password);
        //var retour = "start";
       
        console.log("[api.js]response try ");
        */
      } catch (err) {
        console.log("[api.js]response setTimeout catch: reject");
        reject(new Error(err));
      }
    }, 5000);
  });

export default apiCall;
