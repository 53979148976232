// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox, 
// the second argument is the SVG content stripped of SVG tags
export const logo = ['556 134',`
  <g>
    <g style="fill:#1bbd93;">
      <path d="M 169.88,183.00
      C 169.88,183.00 169.88,232.00 169.88,232.00
        169.88,232.00 158.73,232.00 158.73,232.00
        158.73,232.00 158.73,198.92 158.73,198.92
        158.73,198.92 154.28,232.00 154.28,232.00
        154.28,232.00 146.38,232.00 146.38,232.00
        146.38,232.00 141.69,198.92 141.69,198.92
        141.69,198.92 141.69,232.00 141.69,232.00
        141.69,232.00 130.55,232.00 130.55,232.00
        130.55,232.00 130.55,183.00 130.55,183.00
        130.55,183.00 147.05,183.00 147.05,183.00
        147.52,185.95 148.04,189.42 148.59,193.41
        148.59,193.41 150.34,205.88 150.34,205.88
        150.34,205.88 153.25,183.00 153.25,183.00
        153.25,183.00 169.88,183.00 169.88,183.00 Z
      M 196.55,183.00
      C 196.55,183.00 203.84,232.00 203.84,232.00
        203.84,232.00 190.80,232.00 190.80,232.00
        190.80,232.00 190.17,223.00 190.17,223.00
        190.17,223.00 185.59,223.00 185.59,223.00
        185.59,223.00 184.84,232.00 184.84,232.00
        184.84,232.00 171.64,232.00 171.64,232.00
        171.64,232.00 178.11,183.00 178.11,183.00
        178.11,183.00 196.55,183.00 196.55,183.00 Z
      M 189.80,215.00
      C 189.15,209.11 188.50,201.76 187.86,192.95
        186.57,203.01 185.76,210.36 185.44,215.00
        185.44,215.00 189.80,215.00 189.80,215.00 Z
      M 234.00,183.00
      C 234.00,183.00 234.00,232.00 234.00,232.00
        234.00,232.00 222.83,232.00 222.83,232.00
        222.83,232.00 216.20,209.72 216.20,209.72
        216.20,209.72 216.20,232.00 216.20,232.00
        216.20,232.00 205.55,232.00 205.55,232.00
        205.55,232.00 205.55,183.00 205.55,183.00
        205.55,183.00 216.20,183.00 216.20,183.00
        216.20,183.00 223.34,205.06 223.34,205.06
        223.34,205.06 223.34,183.00 223.34,183.00
        223.34,183.00 234.00,183.00 234.00,183.00 Z
      M 252.28,183.00
      C 252.28,183.00 252.28,232.00 252.28,232.00
        252.28,232.00 239.55,232.00 239.55,232.00
        239.55,232.00 239.55,183.00 239.55,183.00
        239.55,183.00 252.28,183.00 252.28,183.00 Z
      M 279.55,183.00
      C 279.55,183.00 286.84,232.00 286.84,232.00
        286.84,232.00 273.80,232.00 273.80,232.00
        273.80,232.00 273.17,223.00 273.17,223.00
        273.17,223.00 268.59,223.00 268.59,223.00
        268.59,223.00 267.84,232.00 267.84,232.00
        267.84,232.00 254.64,232.00 254.64,232.00
        254.64,232.00 261.11,183.00 261.11,183.00
        261.11,183.00 279.55,183.00 279.55,183.00 Z
      M 272.80,215.00
      C 272.15,209.11 271.50,201.76 270.86,192.95
        269.57,203.01 268.76,210.36 268.44,215.00
        268.44,215.00 272.80,215.00 272.80,215.00 Z"/>
    </g>
    <g style="fill:currentColor;">
      <g>
        <path d="M99.367,36.0577l-39-22.5167a12,12,0,0,0-12,0l-39,22.5166a12.0337,12.0337,0,0,0-6,10.3924V91.4833a12.0331,12.0331,0,0,0,6,10.3923l39,22.5167a12,12,0,0,0,12,0l39-22.5167a12.0333,12.0333,0,0,0,6-10.3923V46.45A12.0336,12.0336,0,0,0,99.367,36.0577Zm-2,55.4256a4,4,0,0,1-2,3.4641l-39,22.5167a4.0006,4.0006,0,0,1-4,0l-39-22.5167a4,4,0,0,1-2-3.4641V46.45a4,4,0,0,1,2-3.4642l39-22.5166a4,4,0,0,1,4,0l39,22.5166a4,4,0,0,1,2,3.4642Z"/>
        <path d="M77.3886,82.0046h-2.866a4.0007,4.0007,0,0,0-1.9247.4934L55.3172,91.9833,35.367,80.4648V57.4872l19.95-11.5185L72.606,55.4236a3.9993,3.9993,0,0,0,1.9192.4906h2.8632a2,2,0,0,0,2-2V51.2024a2,2,0,0,0-1.04-1.7547L59.16,38.9521a8.0389,8.0389,0,0,0-7.8427.09L31.3665,50.56a8.0245,8.0245,0,0,0-3.9995,6.9287v22.976a8,8,0,0,0,4,6.9283l19.95,11.5186a8.0427,8.0427,0,0,0,7.8432.0879l19.19-10.5312a2,2,0,0,0,1.0378-1.7533v-2.71A2,2,0,0,0,77.3886,82.0046Z"/>
      </g>
      <g>
        <path d="M172.1117,45.3618a15.0166,15.0166,0,0,0-15,14.9995V77.6387a15,15,0,0,0,30,0V60.3613A15.0167,15.0167,0,0,0,172.1117,45.3618Zm7,32.2769a7,7,0,0,1-14,0V60.3613a7,7,0,0,1,14,0Z"/>
        <path d="M135.4458,53.4211a7.01,7.01,0,0,1,7.8681,6.0752.9892.9892,0,0,0,.9842.865h6.03a1.0108,1.0108,0,0,0,.9987-1.0971,15.0182,15.0182,0,0,0-15.7161-13.8837A15.2881,15.2881,0,0,0,121.367,60.7968V77.2037A15.288,15.288,0,0,0,135.6112,92.62a15.0182,15.0182,0,0,0,15.7161-13.8842,1.0107,1.0107,0,0,0-.9987-1.0971h-6.03a.9892.9892,0,0,0-.9842.865,7.0106,7.0106,0,0,1-7.868,6.0757,7.1642,7.1642,0,0,1-6.0789-7.1849V60.6057A7.1638,7.1638,0,0,1,135.4458,53.4211Z"/>
        <path d="M218.2891,72.9277a12.1584,12.1584,0,0,0,7.1843-11.0771V58.1494A12.1494,12.1494,0,0,0,213.324,46H196.367a1,1,0,0,0-1,1V91a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V74h6.6215l7.9154,17.4138a1,1,0,0,0,.91.5862h6.5912a1,1,0,0,0,.91-1.4138Zm-.8157-11.0771A4.1538,4.1538,0,0,1,213.3245,66h-9.8511V54h9.8511a4.1538,4.1538,0,0,1,4.1489,4.1494Z"/>
        <path d="M260.367,46h-26a1,1,0,0,0-1,1V91a1,1,0,0,0,1,1h26a1,1,0,0,0,1-1V85a1,1,0,0,0-1-1h-19V72h13a1,1,0,0,0,1-1V65a1,1,0,0,0-1-1h-13V54h19a1,1,0,0,0,1-1V47A1,1,0,0,0,260.367,46Z"/>
        <path d="M298.367,46h-6a1,1,0,0,0-1,1V69.6475a7.0066,7.0066,0,1,1-14,0V47a1,1,0,0,0-1-1h-6a1,1,0,0,0-1,1V69.6475a15.0031,15.0031,0,1,0,30,0V47A1,1,0,0,0,298.367,46Z"/>
        <rect x="307.367" y="46" width="8" height="38" rx="1"/>
      </g>
    </g>
  </g>
`]
