/* eslint-disable promise/param-names */
import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT
} from "../actions/auth";
import { USER_REQUEST } from "../actions/user";
import apiCall from "utils/api";
import VueJwtDecode from 'vue-jwt-decode'
import api from '../../config/api'

const state = {
  token: localStorage.getItem("accessToken") || "",
  status: localStorage.getItem("accessStatus") || "",
  role: localStorage.getItem("role") || "",
  hasLoadedOnce: false
};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  role: state => state.role
};

const urlTgm = api.protocolApi + "://" + api.urlApi + "/api/login_check"

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      console.log("[auth.js] AUTH_REQUEST 1")
      commit(AUTH_REQUEST);
      console.log("[auth.js] AUTH_REQUEST 2")
      apiCall({ url: urlTgm, data: user, method: "POST" })
      //apiCall({ url: urlTgm, data: user, method: "POST" })
        .then(resp => {
          console.log("[auth.js]response : apiCall auth then " + urlTgm);
          //var jwtDecode = VueJwtDecode.decode("eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE1OTEyNTA0NjcsImV4cCI6MTU5MTI1NDA2Nywicm9sZXMiOlsiUk9MRV9BRE1JTiIsIlJPTEVfVVNFUiJdLCJ1c2VybmFtZSI6ImFsZXgucGxhbmNob3RAZ21haWwuY29tIn0.Uas45GjfT530kCiNwvZAiJrw6MzMZkhyaRI-ApPyRPErua0Kvm32NtZaussPoVq_CdZhbWq2QDrrux8I7sH-H0yitRAiTmIlbO26N3BC4POS3tS9PAjdjJDISF9bM6aGQAHZlLd-LUZy58HsFXQwwmlfUiuOQCzU7Bsv8MsnDz6j9-4xj_hUHo1yGwAH0FxRKEK39RxT6-b0bbgkTE1ummU6Fw52HItUc610f3SyANJLSxoZ09nJLKmJhcnUCGz0-MD-ufTIdgf34DKhfiPEfau5Wk8yLr9FFLAaDtCHAs57PcDMw8M0xivYe146XQ3OLIV2_3uiiWGspjFuqZijZ5pzftUSIIigK-Tjyy7NjcCXaT0IvdkECmjtRSMNrRc6rjT2OxIDu6mw95r4_u98E7wV7IE2n2qtBAmngwBSTcrMWNfhzbWdGMdagh8qglpXWxq0TCifU6fmQnotUs5QiVXaOQmXAh4C47l-aCwkaZyyzvDGA-oufrl0VLpeD-xAIcx8saZe0t_SARLB0hGLm_4jkBba94pVk7hCxlQeF-eLUUH98sKa2hyoA5UHOFbpR-2Qkpg1IiqcTHCv1VkWNC7vjJD6rxQHaUD7YuY3rOksOXONhNd4jxY1cx702_cgXhpCZb5e78dmTai79cWifA_WOTMTPIGLrBeJEuPPQFk");
          localStorage.setItem("accessToken", resp.token);
          localStorage.setItem("accessStatus", "login");
          commit(AUTH_SUCCESS, resp);
          dispatch(USER_REQUEST);
          resolve(resp);
          var jwtDecode = VueJwtDecode.decode(resp.token);
          console.log("role : ",jwtDecode.roles )
          localStorage.setItem("role", jwtDecode.roles);
          //this.$router.push('/')
        })
        .catch(err => {
          console.log("[auth.js]response : actions AUTH_REQUEST auth apiCall err ");
          commit(AUTH_ERROR, err);
          //localStorage.removeItem("user-token");
          //localStorage.removeItem("accessToken");
          localStorage.setItem("accessStatus", "error");
          reject(err);
        });
        console.log("[auth.js] AUTH_REQUEST 3")
    });
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise(resolve => {
      console.log("[auth.js]response : apiCall logout then ");
      commit(AUTH_LOGOUT);
      //localStorage.removeItem("user-token");
      localStorage.removeItem("accessToken");
      localStorage.setItem("accessStatus", "logout");
      //this.$router.go({path: "/login"})
      resolve();
    });
  }
};

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = "loading";
    console.log("[auth.js]response : apiCall mutation auth rq then ");
    //this.$router.go({path: "/"})
  },
  [AUTH_SUCCESS]: (state, resp) => {
    console.log("[auth.js]response : apiCall mutation success rq then ");
    state.status = "success";
    state.token = resp.token;
    
    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: state => {
    console.log("[auth.js]response : apiCall mutation error rq then ");
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: state => {
    console.log("[auth.js]response : apiCall mutation logout rq then ");
    state.token = "";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
