/** * Created by vouill on 11/13/17. */

<template>
    <CRow  style="margin: auto;  width: 90%;padding: 10px;">

        <CCol sm="5">
        <div class="card">
          <div class="card-header">
            <h3>Accéder à la plateforme e.targetmania.com</h3>

            <div>
              <form class="login" @submit.prevent="login">
                <h1>Se connecter</h1>
                <label>Email</label>
                <input required v-model="username" type="text" placeholder="Login" />
                <label>Password</label>
                <input
                  required
                  v-model="password"
                  type="password"
                  placeholder="Password"
                />
                <hr />
                <button type="submit">Login</button>
              </form>
            </div>
          </div>
        </div>
        </CCol>
        <CCol sm="5">
            <img class="fit-picture"
     src="/login.jpeg"
     alt="Login">
        </CCol>
      
    </CRow>
</template>

<style>
.login {
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 10px;
}
</style>

<script>
import { AUTH_REQUEST } from "actions/auth";
const header = { headers: { "Content-Type":"application/json" } }
//import Router from 'vue-router';
export default {
  name: "login",
  data() {
    return {
      username: "",
      password: ""
    };
  },
  methods: {
    login: function() {
      const { username, password } = this;
      this.$store.dispatch(AUTH_REQUEST, { username, password })
      .then(() => { 
        this.$router.push('/') 
        } );
    }
  }
};
///------------------------------------------------------------

      
</script>
