<template>
  <router-view></router-view>
</template>

<script>
import Navigation from "components/navigation";
import { USER_REQUEST } from "actions/user";
import SqreenFooter from "./components/footer/index.vue";

export default {
  name: 'App',
  components: {
    SqreenFooter,
    Navigation
  },
  name: "app",
  created: function() {
    if (this.$store.getters.isAuthenticated) {
      this.$store.dispatch(USER_REQUEST);
    }
  }
};
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
