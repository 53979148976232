import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')
const Tactique = () => import('@/views/Tactique')
const Strategie = () => import('@/views/Strategie')
const Diffusion = () => import('@/views/Diffusion')
const Produit = () => import('@/views/Produit')

//const Colors = () => import('@/views/theme/Colors')
//const Typography = () => import('@/views/theme/Typography')

//const Avatar = () => import('@/views/avatar/Avatar')
const monitoringHome = () => import('@/views/monitoring/monitoringHome')
const avatarParSegment = () => import('@/views/avatar/parSegment')
const Chargement = () => import('@/views/Admin/Chargement')
const deliveryGlobal = () => import('@/views/table/deliveryGlobal')

const Reputation = () => import('@/views/Delivrabilite/Reputation')
const ReputationGmail = () => import('@/views/Delivrabilite/ReputationGmail')
const ReputationOutlook = () => import('@/views/Delivrabilite/ReputationOutlook')
const Monitoring = () => import('@/views/Delivrabilite/Monitoring')
const Pricing = () => import('@/views/User/Pricing')
const Conseil = () => import('@/views/Delivrabilite/Conseil')
const ControlAddress = () => import('@/views/Delivrabilite/ControlAddress')
const ApercuEmail = () => import('@/views/Delivrabilite/ApercuEmail')
const ParJour = () => import('@/views/Delivrabilite/ParJour')
const Tasks = () => import('@/views/users/Tasks')
const TasksByUser = () => import('@/views/users/TasksByUser')
const ComptesByUser = () => import('@/views/users/ComptesByUser')
const TasksByRole = () => import('@/views/users/TasksByRole')
const RolesByUser = () => import('@/views/users/RolesByUser')

const SeedList = () => import('@/views/seed/List')

const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Rights = () => import('@/views/pages/Rights') 
//const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')
const Test = () => import('@/views/pages/Test')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')


import Home from "../components/home";
import Account from "../components/account";
import Login from "../components/login";
import store from "../store";

Vue.use(Router)
//-----------------------------------------

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/login");
};

const ifRoleAdm = (to, from, next) => {
  //alert("getters.role : " + store.getters.role.indexOf('ROLE_ADMIN') + " store.getters.role : " + store.getters.role + " isAUth : " +store.getters.isAuthenticated )
  if (store.getters.role.indexOf('ROLE_ADMIN') > -1)
  {
    next("/pages/500");
    return;
  }
  else
    next("/theme/colors");
};



//-------------------------------------------


export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})


function configRoutes () {
  return [
    {
      path: "/account",
      name: "Account",
      component: Account,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/',
      redirect: '/operations',
      name: 'Home',
      component: TheContainer,
      beforeEnter: ifAuthenticated,
      children: [
        {
          path: 'operations',
          name: 'Opérations',
          component: Dashboard,
          beforeEnter: ifAuthenticated,
        },
        {
          path: 'tactiques',
          name: 'tactiques',
          component: Tactique,
          beforeEnter: ifAuthenticated,
        },
        {
          path: 'strategies',
          name: 'Strategies',
          component: Strategie,
          beforeEnter: ifAuthenticated,
        },
        {
          path: 'diffusions',
          name: 'Diffusions',
          component: Diffusion,
          beforeEnter: ifAuthenticated,
        },
        {
          path: 'produits',
          name: 'Produits',
          component: Produit,
          beforeEnter: ifAuthenticated,
        },
        {
          path: 'operations',
          name: 'dashboard', 
          //component: Avatar,
          //redirect: '/avatar/sociologie',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            /*{
              path: '/monitoring',
              name: 'Monitoring',
              component: monitoringHome
            },*/
            {
              path: '/avatar',
              name: 'Avatar',
              component: avatarParSegment
            },
            {
              path: '/pricing',
              name: 'Pricing',
              component: Pricing
            },
            {
              path: '/monitoring',
              name: 'Monitoring',
              component: Monitoring
            },
            {
              path: '/admin/chargement',
              name: 'chargement',
              component: Chargement,
              //beforeEnter: ifRoleAdm
            },
            {
              path: '/admin/table/deliveryGlobal',
              name: 'deliveryGlobal',
              component: deliveryGlobal,
              //beforeEnter: ifRoleAdm
            },
            {
              path: '/reputation',
              name: 'reputation',
              component: Reputation,
              //beforeEnter: ifRoleAdm
            },
            {
            path: '/reputation/gmail',
            name: 'reputationGmail',
            component: ReputationGmail
            },
            {
              path: '/reputation/outlook',
              name: 'reputationOutlook',
              component: ReputationOutlook
              },
            {
              path: '/conseil',
              name: 'conseil',
              component: Conseil,
            },
            {
              path: '/controlAddress',
              name: 'controlAddress',
              component: ControlAddress,
            },
            {
              path: '/apercuEmail',
              name: 'apercuEmail',
              component: ApercuEmail,
            },
            {
              path: '/parJour/:date',
              name: 'parJour',
              component: ParJour,
            },
            {
              path: '/tasks',
              name: 'Tasks',
              component: Tasks,
            },
            {
              path: '/comptesByUser',
              name: 'ComptesByUser',
              component: ComptesByUser,
            },            
            {
              path: '/tasksByUser',
              name: 'TasksByUser',
              component: TasksByUser,
            },
            {
              path: '/tasksByRole',
              name: 'TasksByRole',
              component: TasksByRole,
            },
            {
              path: '/rolesByUser',
              name: 'RolesByUser',
              component: RolesByUser,
            },
            {
              path: '/seed/list',
              name: 'SeedList',
              component: SeedList,
            },
            {
              path: '/user',
              name: 'User',
              component: User,
            }
          ]
        },
        

        
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'rights',
          name: 'rights',
          component: Rights,
        },
        /*{
          path: 'register',
          name: 'Register',
          component: Register
        },*/
        {
          path: 'test',
          name: 'test',
          component: Test
        }
      ]
    },
   
  ]
}

