//const urlApi = "https://api.targetmania.com"

//const env = "local"
const env = "prod"
var urlApi = ""
var protocolApi = ""
const base_api = "/api"
const societe = "e.targetmania"
var urlNode = ""
if(env == "local"){
    urlApi = "api.targetmania.local"; 
    protocolApi = "http"; //dev
    urlNode = "localhost:8081"
}
else{
    urlApi = "api.targetmania.com"; 
    protocolApi = "https"; //prod
    urlNode = "email.targetmania.com";//http://51.210.250.226
}
var urlSendIt = "email.targetmania.com"
export default {
    urlApi,
    protocolApi,
    base_api,
    urlNode,
    societe ,
    urlSendIt
}


