import "core-js/stable"
import Vue from "vue"
import App from "./App"
import router from "./router"
import store from "./store"
import Loading from "components/lib/loading";
import CenterContainer from "components/lib/center-container";
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
//import vuetify from './plugins/vuetify';

import { abilitiesPlugin } from '@casl/vue';
//import ability from './services/ability';
//import abilityPlugin from './ability-plugin';

//const defineAbilitiesFor = require('./resources/ability');

import axios from 'axios'
Vue.config.productionTip = false;

//let ability = defineAbilitiesFor(activeUser.id);

Vue.config.performance = true
//Vue.use(CoreuiVue,abilityPlugin, ability)
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)
Vue.prototype.$axios = axios
Vue.component("loading", Loading);
Vue.component("center-container", CenterContainer);
new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: "<App/>",
  components: {
    App
  }
})
